import { AppProps } from 'next/app'

import { NextPageWithLayout } from '@shared/models/next-page'

import '../styles/globals.scss'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || (page => page);

  return getLayout(<Component {...pageProps} />)
}
